import { createSlice } from "@reduxjs/toolkit";

export const cartProdSlice = createSlice({
    name:"cart_initial_prod",
    initialState:{
        'initial_prod':localStorage.getItem('woo_cart_prod_list') ? JSON.parse(localStorage.getItem('woo_cart_prod_list')) : [],
    },
    reducers:{
        saveCartProduct:(state,action)=>{
            state.initial_prod = action.payload;
            localStorage.setItem('woo_cart_prod_list', JSON.stringify(state.initial_prod));
        },
        addToCart:(state,action)=>{
            //new array added
            let produs = action.payload;
            console.log(produs)
            //return array of id
            let id_prod_cart = state.initial_prod.map(item => item.product_id);
            // check if id exist to prevent duplicate value
            let existProdus = id_prod_cart.includes(produs.product_id);
            
            if(existProdus === false){
                state.initial_prod.push(produs);
                localStorage.setItem('woo_cart_prod_list', JSON.stringify(state.initial_prod));
            }
        },
        removeProductFromCart:(state,action)=>{
            const idToRemove = action.payload;
            state.initial_prod = state.initial_prod.filter(item => item.product_id !== idToRemove);
            localStorage.setItem('woo_cart_prod_list', JSON.stringify(state.initial_prod));
        },
        removeAllProduct:(state)=>{
            localStorage.removeItem("woo_cart_prod_list");
        }
    }
});
export const {saveCartProduct,addToCart,removeProductFromCart,removeAllProduct} = cartProdSlice.actions;
export default cartProdSlice.reducer;