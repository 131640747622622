import { createSlice } from "@reduxjs/toolkit";
import ShortUniqueId from 'short-unique-id';

export const personsSlice = createSlice({
    name: "persons_multi_order",
    initialState: {
        persons: JSON.parse(localStorage.getItem('sante_persons')) || [
            {
                position: 1,
                id: "8eIIBs1",
                first_name: '',
                last_name: '',
                birth_day: '',
                cnp: '',
                product: [],
                genetic_Select_Q1:'',
                genetic_Start_Q2_Info:'',
                genetic_Start_Q3_Info:'',
                genetic_Q1:false,
                genetic_Q1_INFO:'',
                genetic_Q2:false,
                genetic_Q2_INFO:'',
                genetic_Q3:false,
                genetic_Q3_INFO:'',
                genetic_Q4:false,
                genetic_Q4_INFO:'',
                genetic_Q5:false,
                genetic_Q5_INFO:'',
                genetic_Q6:false,
                genetic_Q6_INFO:'',
                genetic_Q7:false,
                genetic_Q7_INFO:[],
                genetic_Q8:false,
                genetic_Q9:'fizic',
                genetic_10:'Nu am preferințe',
            }, {
                position: 2,
                id: "UQm61A2",
                first_name: '',
                last_name: '',
                birth_day: '',
                cnp: '',
                product: [],
                genetic_Select_Q1:'',
                genetic_Start_Q2_Info:'',
                genetic_Start_Q3_Info:'',
                genetic_Q1:false,
                genetic_Q1_INFO:'',
                genetic_Q2:false,
                genetic_Q2_INFO:'',
                genetic_Q3:false,
                genetic_Q3_INFO:'',
                genetic_Q4:false,
                genetic_Q4_INFO:'',
                genetic_Q5:false,
                genetic_Q5_INFO:'',
                genetic_Q6:false,
                genetic_Q6_INFO:'',
                genetic_Q7:false,
                genetic_Q7_INFO:[],
                genetic_Q8:false,
                genetic_Q9:'fizic',
                genetic_10:'Nu am preferințe',
            }
        ],
    },



    reducers: {
        addPerson: (state) => {
            // Check if we have fewer than 10 persons
            if (state.persons.length < 10) {
                const newId = state.persons.length;
                const uid = new ShortUniqueId();
                state.persons.push({
                    position: newId + 1,
                    id: uid.rnd() + newId,
                    first_name: '',
                    last_name: '',
                    birth_day: '',
                    cnp: '',
                    product: [],
                    genetic_Select_Q1:'',
                    genetic_Start_Q2_Info:'',
                    genetic_Start_Q3_Info:'',
                    genetic_Q1:false,
                    genetic_Q1_INFO:'',
                    genetic_Q2:false,
                    genetic_Q2_INFO:'',
                    genetic_Q3:false,
                    genetic_Q3_INFO:'',
                    genetic_Q4:false,
                    genetic_Q4_INFO:'',
                    genetic_Q5:false,
                    genetic_Q5_INFO:'',
                    genetic_Q6:false,
                    genetic_Q6_INFO:'',
                    genetic_Q7:false,
                    genetic_Q7_INFO:[],
                    genetic_Q8:false,
                    genetic_Q9:'fizic',
                    genetic_10:'Nu am preferințe',
                });
                localStorage.setItem('sante_persons', JSON.stringify(state.persons));
            } else {
                console.warn('Cannot add more than 10 persons.');
            }
        },
removePerson: (state, action) => {
    const idToRemove = action.payload;

    // Check if the persons array has at least one person
    if (state.persons.length > 0) {
        // Filter out the person to remove
        const updatedPersons = state.persons.filter(person => person.id !== idToRemove);

        // If the new array length is 0, log a warning
        if (updatedPersons.length === 0) {
            console.warn('Cannot delete the last person.');
        }

        // Update the state
        state.persons = updatedPersons;

        // Persist to localStorage
        localStorage.setItem('sante_persons', JSON.stringify(state.persons));
    } else {
        console.warn('No persons to delete.');
    }
},

        
        updatePerson: (state, action) => {
            const { id, val, key } = action.payload;
            //get array index
            const indexPerson = state.persons.findIndex(p => p.id === id);
            if (indexPerson !== -1) {
                const updatePrson = [...state.persons];
                
                //update person by key and value
                updatePrson[indexPerson] = {
                    ...updatePrson[indexPerson],
                    [key]: val
                };

                //update state
                state.persons = updatePrson;
                //update local
                localStorage.setItem('sante_persons', JSON.stringify(state.persons));
            }
        },
        updatePersonProducts: (state, action) => {
            const { id, updates } = action.payload;
            const person = state.persons.find(p => p.id === id);
            if (person) {
                Object.assign(person, updates);
                localStorage.setItem('sante_persons', JSON.stringify(state.persons));
            }
        },
        removeAllPersons:(state)=>{
            localStorage.removeItem("sante_persons");
        }
        
    }
});

export const { addPerson, removePerson, updatePerson,updatePersonProducts,removeAllPersons } = personsSlice.actions;
export default personsSlice.reducer;
